<template>
  <div class="disks-container" id="music">

    <!--  Carousel  -->

    <carousel :settings="settings" :breakpoints="breakpoints">

      <!--  Disque  -->

      <slide v-for="slide in slides" :key="slide">
        <div class="carousel-item f f-c f-aife f-jcsb f-nw">
          <div class="title f f-r f-nw f-aic f-jcc">
            <div class="line"></div>
            <p class="disk-title-text">{{ slide.title }}</p>
          </div>
          <a :href="slide.link" target="_blank"><img :src="slide.image" alt=""></a>
        </div>
      </slide>

      <!--  Système de navigation intégré du carousel  -->

      <template #addons>
        <navigation @prev="prevSlide" />
      </template>

    </carousel>

    <!--  Liens vers réseaux sociaux  -->

    <div class="links-social-media f f-r f-aic f-jcc">
      <a href="https://www.youtube.com/user/ManuelaRufolo" target="_blank"><img src="../assets/images/icons/icon_youtube.svg" alt=""></a>
      <a href="https://www.facebook.com/manuelarufololux" target="_blank"><img src="../assets/images/icons/icon_fb.svg" alt=""></a>
      <a href="https://www.instagram.com/manuellarufolo/" target="_blank"><img src="../assets/images/icons/icon_insta.svg" alt=""></a>
      <a href="https://open.spotify.com/artist/2WzJV94zzhC9nPtGjJzbiK?si=toq_i3e1RzCd3hx2HY4Wqg" target="_blank"><img src="../assets/images/icons/icon_spotify.svg" alt=""></a>
    </div>

  </div>
</template>

<script>
import { Carousel, Slide, Navigation } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

import disk1 from "../assets/images/disc_amaranto.png";
import disk2 from "../assets/images/disc_the_moment_you_feel.png";
import disk3 from "../assets/images/disc_domani.png";
import disk4 from "../assets/images/disc_careless.png";
import disk5 from "../assets/images/disc_el_bailarino.png";

import arrowNext from "../assets/images/icons/fleche_droite_orange.svg";
import arrowPrev from "../assets/images/icons/fleche_gauche_orange.svg";

export default {
  name: 'Disks',
  data () {
    return {
      slides: [
        {
          title: 'El Bailarino',
          image: disk5,
          link: "https://open.spotify.com/album/1SvkPyzm05MyZ2IFg9hEbB"
        },
        {
          title: 'Careless Souls',
          image: disk4,
          link: "https://open.spotify.com/album/13mwo4gFoXM35lElG0qMgx"
        },
        {
          title: 'Amaranto',
          image: disk1,
          link: "https://open.spotify.com/album/6UfpNjQGsjVaGpiEeI6Ged"
        },
        {
          title: 'The moment you feel',
          image: disk2,
          link: "https://open.spotify.com/album/29ZWobN01CoRaNlDWsCjHB"
        },
        {
          title: 'Domani',
          image: disk3,
          link: "https://open.spotify.com/album/2o5a3HgxT1Xv4NEReI5zhY"
        },
        {
          title: 'El Bailarino',
          image: disk5,
          link: "https://open.spotify.com/album/1SvkPyzm05MyZ2IFg9hEbB"
        },
        {
          title: 'Careless Souls',
          image: disk4,
          link: "https://open.spotify.com/album/13mwo4gFoXM35lElG0qMgx"
        },
        {
          title: 'Amaranto',
          image: disk1,
          link: "https://open.spotify.com/album/6UfpNjQGsjVaGpiEeI6Ged"
        },
        {
          title: 'The moment you feel',
          image: disk2,
          link: "https://open.spotify.com/album/29ZWobN01CoRaNlDWsCjHB"
        },
        {
          title: 'Domani',
          image: disk3,
          link: "https://open.spotify.com/album/2o5a3HgxT1Xv4NEReI5zhY"
        },
      ],
      settings: {
        itemsToShow: 2,
        wrapAround: true,
        snapAlign: 'start',
        autoplay: 4000,
      },
      breakpoints: {
        // 700px and up
        0: {
          itemsToShow: 1,
        },
        // 1024 and up
        1580: {
          itemsToShow: 2,
        },
      },
    }
  },
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  mounted() {

    /* Changement visuel du bouton Next du slide */

    let imageSlideNext = new Image();
    imageSlideNext.src = arrowNext;

    let buttonSlideNext = document.getElementsByClassName("carousel__next")[0];

    buttonSlideNext.appendChild(imageSlideNext);

    /* Changement visuel du bouton Next du slide */

    let imageSlidePrev = new Image();
    imageSlidePrev.src = arrowPrev;

    let buttonSlidePrev = document.getElementsByClassName("carousel__prev")[0];

    buttonSlidePrev.appendChild(imageSlidePrev);
  }
}
</script>

<style>
@import "../styles/variables.css";
@import '../styles/flex_class.css';
@import '../styles/texts.css';

.disks-container {
  overflow-x: hidden;

  padding: 60px;
  padding-top: 130px;
  padding-bottom: 130px;
}

/* Slides */

  .carousel-item {
    max-height: 547px;
    max-width: 674px;
    width: 100%;
  }

  .carousel-item img {
    width: 100%;
  }

  /* Texte titre des disques */

    .carousel-item .title {
      width: 70%;
      margin-bottom: 60px;
    }

    .carousel-item .title .line {
      flex-grow: 1; /* Prend le reste de l'espace disponible en width */
      height: 1px;

      background-color: var(--main-color);

      margin-right: 40px;
    }

    .carousel-item .title .disk-title-text {
      width: fit-content;
      margin: 0;

      max-width: calc(100% - 40px);

      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    @media (max-width: 900px) {

      .carousel-item .title {
        margin-bottom: 20px;
      }

      .carousel-item .title .line {
        margin-right: 15px;
      }

      .carousel-item .title .disk-title-text {
        font-size: 23px;
      }

    }

/* Flèches de navigation */

  .carousel__next {
    height: 75px;
    width: 40px;

    border-radius: 0;

    background: none;
  }
  .carousel__next svg {
    display: none;
  }
  .carousel__next img {
    height: 100%;
    width: 100%;
  }

  .carousel__prev {
    height: 75px;
    width: 40px;

    border-radius: 0;

    background: none;
  }
  .carousel__prev svg {
    display: none;
  }
  .carousel__prev img {
    height: 100%;
    width: 100%;
  }

  @media (max-width: 900px) {

    .carousel__next {
      height: 34px;
      width: 17px;

      margin-top: 38vw;
      margin-right: 10vw;
    }

    .carousel__prev {
      height: 34px;
      width: 17px;

      margin-top: 38vw;
      margin-left: 10vw;
    }

  }

/* Liens vers les réseaux sociaux */

  .links-social-media {
    margin-top: 100px;
  }

  .links-social-media a {
    margin: 0 30px;
  }

  .links-social-media img {
    height: 80px;
  }

  @media (max-width: 900px) {

    .links-social-media {
      margin-top: 115px;
    }

    .links-social-media a {
      margin: 0 15px;
    }

    .links-social-media img {
      height: 40px;
    }

  }

</style>