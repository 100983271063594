<template>
  <div class="images-container">

    <vue-picture-swipe :options="options" :items="items"></vue-picture-swipe>

  </div>
</template>

<script>
import VuePictureSwipe from 'vue-picture-swipe';

import Image1 from '../assets/images/images_section_7.jpg';
import Image2 from '../assets/images/images_section_11.jpeg';
import Image3 from '../assets/images/images_section_14.jpg';
import Image4 from '../assets/images/images_section_13.jpeg';
import Image5 from '../assets/images/images_section_5.png';
import Image6 from '../assets/images/images_section_6.png';

export default {
  name: 'Images',
  components: {
    VuePictureSwipe,
  },
  data() {
    return {
      images: [
        Image1,
        Image2,
        Image3,
        Image4,
        Image5,
        Image6,
      ],
      options: {
        bgOpacity: 0.8,
      },
      items: [
        {
          src: Image1,
          thumbnail: Image1,
          w: 1334,
          h: 890,
        },
        {
          src: Image2,
          thumbnail: Image2,
          w: 3550,
          h: 3801
        },
        {
          src: Image3,
          thumbnail: Image3,
          w: 2048,
          h: 1052
        },
        {
          src: Image4,
          thumbnail: Image4,
          w: 4738,
          h: 3159
        },
        {
          src: Image5,
          thumbnail: Image5,
          w: 2048,
          h: 1365,
        },
        {
          src: Image6,
          thumbnail: Image6,
          w: 2048,
          h: 1365
        },
      ]
    }
  },
  mounted() {
    // Récupérer la div parent des figures
    let parentFigure = document.getElementsByClassName("my-gallery")[0];
    // Ajouter le style
    parentFigure.classList.add("f", "f-r", "f-w", "f-aic", "f-jcc");
    parentFigure.style.width = "100%";
    parentFigure.style.overflowX = "hidden";

    // Récupérer les figures
    let figures = document.getElementsByClassName("gallery-thumbnail");
    // Rajouter le style pour chaque figure
    for (let i = 0; i < figures.length; i++) {

      // Style des figures
      figures[i].style.margin = "0";
      figures[i].style.height = "29vw";
      figures[i].style.maxHeight = "540px";
      figures[i].style.minHeight = "317px";
      figures[i].style.width = "calc(100% / 3)";
      figures[i].style.minWidth = "600px";
      figures[i].style.flexGrow = "1";
      figures[i].style.position = "relative";
      figures[i].style.overflowX = "hidden";
      figures[i].style.overflowY = "hidden";

      // Faire en sorte que l'image prenne la taille de son parent (la mettre en cover)

      // Création d'une div ayant pour background l'image à afficher
      let divForBackground = document.createElement("div");
      divForBackground.style.height = "110%";
      divForBackground.style.width = "110%";
      divForBackground.style.background = "url(\""+ this.images[i] +"\") no-repeat 50%";
      divForBackground.style.backgroundSize = "cover";
      divForBackground.style.position = "absolute";
      divForBackground.style.top = "50%";
      divForBackground.style.left = "50%";
      divForBackground.style.transform = "translateY(-50%) translateX(-50%)";
      divForBackground.style.transition = "all 0.2s";

      // Effet de survol
      divForBackground.onmouseover = function(){
        divForBackground.style.height = "120%";
        divForBackground.style.width = "120%";
      }
      divForBackground.onmouseleave = function(){
        divForBackground.style.height = "110%";
        divForBackground.style.width = "110%";
      }

      // Récupération de la balise "a" pour lui ajouter la nouvelle balise
      let linkOfFigure = figures[i].getElementsByTagName('a')[0];

      // Ajouter la nouvelle balise à la balise "a"
      linkOfFigure.appendChild(divForBackground);

      // Récupérer les images pour les rendre invisible
      let imageOfFigure = figures[i].getElementsByTagName('img')[0];
      imageOfFigure.style.display = "none";
    }
  }
}
</script>

<style scoped>
@import "../styles/variables.css";
@import '../styles/flex_class.css';
@import '../styles/texts.css';

.images-container {
  width: 100%;
  height: fit-content;
  min-height: 100vh;
}

.figure-style {
  width: 30px;
}
</style>