<template>
  <header class="header-container" id="home">

    <!--  Barre de navigation  -->
    <nav>

      <!--  Logo  -->
      <img src="../assets/images/logos/logo_manuela.svg" class="logo" alt="LOGO">

      <!--  Menu taille laptop  -->
      <div class="links-container l f f-c f-aife f-jcfe">

        <div class="links f f-r f-aifs f-jcsb">
          <a class="header-links-style" href="#home">Home</a>
          <a class="header-links-style" href="#music">Music</a>
          <a class="header-links-style" href="#about">About Manuela</a>
          <a class="header-links-style" href="#videos">Videos</a>
          <a class="header-links-style" href="#contact">Contact</a>
        </div>

        <div class="line"></div>

      </div>

    </nav>

    <!--  Textes  -->

    <div class="texts-container f f-c f-aifs f-jcfe">

      <h1 id="header-text-slide-1" class="header-title-style">Vocalist and <br> songwriter</h1>
      <p id="header-text-slide-2" class="header-text-style">
        Originally hailing from Italy, based in Luxembourg since 2014. She started singing at a very early age.
        After attending and also winning several local singing contests in Italy, she started to sing in a cover band,
        mainly for private events until 2011, when she realized she had a greater inspiration and mission,
        thus starting to write her own original music and getting more and more into songwriting...
      </p>
      <a class="button-plus" href="#about">
        <div class="line"></div>
        <div class="line rotate"></div>
      </a>

      <div class="space m"></div>

    </div>

    <!--  Fleche vers le bas (uniquement taille laptop)  -->

    <div class="arrow-bottom m f f-c f-jcc f-aic">
      <a href="#music">
        <img src="../assets/images/icons/fleche_bas_white.svg" alt="">
        <img src="../assets/images/icons/fleche_bas_orange.svg" class="only-hover" alt="">
      </a>
      <div class="line"></div>
    </div>

  </header>
</template>

<script>
export default {
  name: 'Header',
  mounted() {
    // Récupérer les textes et les mettre dans un tableau
    let item1 = document.getElementById("header-text-slide-1");
    let item2 = document.getElementById("header-text-slide-2");

    let itemsToSlide = [item1, item2];
    itemsToSlide[0].style.marginLeft = "168px";
    itemsToSlide[1].style.marginLeft = "275px";

    // Actualiser la position de chaque éléments au debut
    setTimeout(function() {
      itemsToSlide[0].style.removeProperty("margin-left");
      itemsToSlide[0].style.opacity = "1";
      setTimeout(function() {
        itemsToSlide[1].style.removeProperty("margin-left");
        itemsToSlide[1].style.opacity = "1";
        setTimeout(function() {
          itemsToSlide[0].style.transition = "none";
          itemsToSlide[1].style.transition = "none";
        }, 1000);
      }, 500);
    }, 500);
  }
}
</script>

<style scoped>

/* Les imports */
@import '../styles/variables.css';
@import '../styles/flex_class.css';
@import '../styles/texts.css';

/* Conteneur en général */

  .header-container {
    height: 100vh;
    width: 100%;

    overflow-x: hidden;

    background-color: var(--secondary-text-color);

    position: relative;
  }

/* Image de fond */

  header {
    z-index: 1;

    background: url("../assets/images/image_header_manuela_rufolo.png") no-repeat 50%;
    background-size: cover;
  }

/* Nav */

  nav {
    z-index: 100;

    position: relative;
  }

  /* Version Laptop */

    nav img.logo {
      width: 550px;

      position: absolute;
      left: 65px;
      top: 40px;
    }

    nav .links-container.l {
      width: calc(100% - 720px);

      position: absolute;
      right: 75px;
      top: 65px;
    }

    nav .links-container.l .links {
      width: 629px;
    }

    nav .links-container.l .line {
      width: 100%;
      height: 1px;

      margin-top: 10px;

      background-color: var(--primary-text-color);
    }

    @media (max-width: 850px) {

      nav img.logo {
        max-width: 255px;
        width: 70%;

        left: 8px;
        top: 10px;
      }

    }

/* Textes et Boutons */

  .texts-container {
    z-index: 10;

    height: 100%;
    width: fit-content;

    position: absolute;
    top: 0;
    left: 0;
  }

  .texts-container h1 {
    width: 1020px;

    margin-left: 68px;
    margin-top: 0;
    margin-bottom: 0;

    opacity: 0;

    transition: margin-left 1s, opacity 1s;
  }

  .texts-container p {
    width: 775px;

    margin-left: 175px;
    margin-top: 0;
    margin-bottom: 0;

    opacity: 0;

    transition: margin-left 1s, opacity 1s;
  }

  @media (max-width: 1050px) {

    .texts-container h1 {
      width: calc(100% - 14px);

      margin-left: 14px;

      font-size: 58px;
      line-height: 70px;
    }

    .texts-container p {
      width: calc(100% - 50px);

      margin-left: 50px;

      font-size: 18px;
      line-height: 28px;
    }

  }
  @media (max-width: 650px) {

    .texts-container h1 {
      width: calc(100% - 14px);

      margin-left: 14px;

      font-size: 30px;
      line-height: 43px;
    }

    .texts-container p {
      width: calc(100% - 30px);

      margin-left: 14px;

      font-size: 14px;
      line-height: 22px;
    }

  }

  /* Bouton + */

    .texts-container .button-plus {
      height: 71px;
      width: 71px;

      border: 1px solid var(--primary-text-color);
      border-radius: 50%;

      margin-left: 175px;
      margin-top: 60px;

      position: relative;
    }

    .texts-container .button-plus .line {
      height: 1px;
      width: 56%;

      background: var(--primary-text-color);

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
    }

    .texts-container .button-plus .line.rotate {
      transform: translateY(-50%) translateX(-50%) rotate(90deg);
    }

    @media (max-width: 1050px) {

      .texts-container {
        width: 100%;
      }

      .texts-container .button-plus {
        height: 50px;
        width: 50px;

        margin-left: 0;
        margin-top: 22px;

        left: 50%;
        transform: translateX(-50%);
      }

    }

    /* Bouton onHover */

      .texts-container .button-plus {
        transition: border 0.2s;
      }
      .texts-container .button-plus .line {
        transition: background 0.2s;
      }

      .texts-container .button-plus:hover {
        border: 1px solid var(--main-color);
      }
      .texts-container .button-plus:hover .line {
        background: var(--main-color);
      }

  /* Espace pour la version laptop */

  .texts-container .space {
    height: 140px;
  }

  @media (max-width: 1050px) {

    .texts-container .space {
      min-height: 23px;
      height: 15vw;
      max-height: 20vh;
    }

  }

/* Fleche vers le bas */

  .arrow-bottom {
    z-index: 11;

    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .arrow-bottom a {
    margin-bottom: 30px;

    position: relative;
  }

  .arrow-bottom a img {
    width: 25px;
  }

  .arrow-bottom .line {
    width: 1px;
    height: 70px;
    background: var(--primary-text-color);
  }

  .arrow-bottom a > * {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

    transition: all 0.2s;
  }
  .arrow-bottom a .only-hover {
    opacity: 0;
  }
  .arrow-bottom a:hover .only-hover {
    opacity: 1;
  }

  @media (max-width: 1050px) {

    .arrow-bottom {
      display: none;
    }

  }


@media (max-width: 1370px) {
  .l {
    display: none;
  }
}

</style>