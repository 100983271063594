<template>
  <section class="about-container" id="about">

    <!--  Première partie de la section About  -->

    <article class="first f f-r f-aic f-jcc f-w">

      <img class="title-about" src="../assets/images/titles/about_title.svg" alt="">

      <div class="image">
        <div class="cadre"></div>
      </div>

      <!--  Partie textuelle  -->

      <div class="text-section f f-c f-aic f-jcc f-nw">

        <div class="space"></div>
        <p class="about-text-style">
          Manuela Rufolo is an Italian-born vocalist and songwriter based in Luxembourg.
          <br><br>
          From her arrival, Manuela has gradually become well known in Luxembourg as well as in the Greater Region for her vocal skills and her talent for writing memorable melodies that connect with the listeners.
          <br><br>
          Her music displays her artistic diversity: she flawlessly switches between energetic, thoughtful songs, heartbreaking ballads, songs with contagious beat and it is characterized by a perfect fusion of her charismatic voice with captivating melodies and a harmonious blend of pop and more experimental musical elements, which bring depth and complexity to her tracks.
          <br><br>
          Her live shows reflect her distinctive artistic vision and are the best way to discover the breadth of her talent: storytelling, alluring melodies and a powerhouse voice backed up by outstanding musicians, a blend that creates a unique and fascinating musical atmosphere.
        </p>
        <div class="line"></div>

      </div>

    </article>

    <!--  Deuxième partie de la section About  -->

    <article class="second f f-rr f-aic f-jcc f-w">

      <div class="image">
        <div class="cadre"></div>
      </div>

      <!--  Partie textuelle  -->

      <div class="text-section f f-c f-aic f-jcc f-nw">

        <div class="space"></div>

        <img src="../assets/images/esperluette.svg" alt="">

        <p class="about-text-style">
          Her latest single called “El Bailarino” feat. Kader Fahem reflects Manuela’s new and strong need to get back to her roots  (that’s why the lyrics in Italian) but, as the title in Spanish (El Bailarino) implies, also the need to welcome new music elements inspired by Spain, especially by Andalucía and traditional Spanish music.
          The collaboration with Kader Fahem, a guitarist of great experience and talent in the flamenco and Arabic-Andalusian music style, allowed her to express a new music identity, with the creation of a unique fusion of Mediterranean horizons, between pop, jazz and world music.
        </p>
        <div class="line"></div>

      </div>

    </article>

  </section>
</template>

<script>
export default {
  name: 'About',
}
</script>

<style scoped>
@import "../styles/variables.css";
@import '../styles/flex_class.css';
@import '../styles/texts.css';

.about-container {
  overflow-x: hidden;
  overflow-y: hidden;
}

/* Première section */

  article.first {
    position: relative;

    height: fit-content;
  }

  article.first .title-about {
    z-index: 2;

    width: 35%;

    position: absolute;
    top: 7%;
    left: 38%;
  }

  article.first .image {
    width: 44%;
    height: 100%;
    min-height: 100vh;

    background: url("../assets/images/photo_manuela_about_1_new.jpg") no-repeat 50% top;
    background-size: cover;

    position: relative;
  }

  article.first .image .cadre {
    z-index: 0;

    height: calc(100% - 110px);
    width: calc(100% - 130px);

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);

    border: 1px solid var(--main-color);
  }

  @media (max-width: 1500px) {

    article.first .image {
      width: 100%;
    }

    article.first .title-about {
      width: 50%;

      position: absolute;
      top: 38px;
      left: 42px;
    }

    article.first .image .cadre {
      height: calc(100% - 80px);
      width: calc(100% - 60px);
    }

  }

  /* Première section - Partie texte */

    article.first .text-section {
      flex-grow: 1; /* Prend le reste de l'espace disponible en width */
      height: 100%;
    }

    article.first .text-section > * {
      width: 80%;
      max-width: 700px;
    }

    article.first .text-section .space {
      height: 200px;
    }

    article.first .text-section .line {
      height: 1px;

      margin-top: 75px;

      background-color: var(--main-color);
    }

    @media (max-width: 1500px) {

      article.first .text-section .space {
        height: 75px;
      }

      article.first .text-section > * {
        width: 90%;
      }

      article.first .text-section .line {
        margin-bottom: 75px;
      }

    }

    @media (max-width: 550px) {

      article.first .text-section p {
        font-size: 14px;
      }

    }

/* Deuxième section */

  article.second {
    height: fit-content;
  }

  article.second .image {
     width: 56%;
     height: 100%;
     min-height: 100vh;

     background: url("../assets/images/photo_manuela_about_2.jpg") no-repeat 50% top;
     background-size: cover;

     position: relative;
   }

  article.second .image .cadre {
    z-index: 0;

    height: calc(100% - 85px);
    width: 73%;

    position: absolute;
    top: 32px;
    right: 64px;

    border: 1px solid var(--main-color);
  }

  @media (max-width: 1500px) {

    article.second .image {
      width: 100%;
    }

    article.second .image .cadre {
      height: calc(100% - 80px);
      width: calc(100% - 60px);

      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
    }

  }

  /* Deuxième section - Partie texte */

    article.second .text-section {
      position: relative;

      flex-grow: 1; /* Prend le reste de l'espace disponible en width */
      height: 100%;
    }

    article.second .text-section > * {
      width: 80%;
      max-width: 600px;

      z-index: 2;
    }

    article.second .text-section .line {
      height: 1px;

      margin-top: 75px;

      background-color: var(--main-color);
    }

    @media (max-width: 1500px) {

      article.second .text-section .space {
        height: 215px;
      }

      article.second .text-section .line {
        margin-bottom: 75px;
      }

    }

    @media (max-width: 550px) {

      article.second .text-section p {
        font-size: 14px;
      }

    }

    /* Image esperluette */

      article.second .text-section img {
        position: absolute;
        top: 0;
        left: 0;
        transform: translateY(-50%) translateX(30%);

        width: 195px;
        height: auto;

        z-index: 1;
      }

      @media (max-width: 1500px) {

        article.second .text-section img {
          position: absolute;
          top: 75px;
          left: 50%;
          transform: translateX(-50%);

          width: 120px;
        }

      }

</style>