<template>
  <div class="videos-container f f-c f-nw f-aic f-jcfs" id="videos">

    <div class="cadre"></div>

    <!--  Titre  -->

    <h1 class="video-contact-title-style">Videos</h1>

    <!--  Vidéos Youtube  -->

    <section class="videos-section f f-r f-w f-aic f-jcc">
      <iframe src="https://www.youtube.com/embed/qMpq20Q60_U" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      <iframe src="https://www.youtube.com/embed/srKsofSmikE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
<!--      <iframe src="https://www.youtube.com/embed/qItDmVN_EA4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->
<!--      <iframe src="https://www.youtube.com/embed/7T-a1LsvnFc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->
      <iframe src="https://www.youtube.com/embed/-AR8D7OgJy8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
<!--      <iframe src="https://www.youtube.com/embed/knr8UFL_beo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->
    </section>

    <!--  Liens vers réseaux sociaux  -->

    <div class="links-social-media f f-r f-aic f-jcc">
      <a href="https://www.youtube.com/user/ManuelaRufolo" target="_blank"><img src="../assets/images/icons/icon_youtube_white.svg" alt=""></a>
      <a href="https://www.facebook.com/manuelarufololux" target="_blank"><img src="../assets/images/icons/icon_fb_white.svg" alt=""></a>
      <a href="https://www.instagram.com/manuellarufolo/" target="_blank"><img src="../assets/images/icons/icon_insta_white.svg" alt=""></a>
      <a href="https://open.spotify.com/artist/2WzJV94zzhC9nPtGjJzbiK?si=toq_i3e1RzCd3hx2HY4Wqg" target="_blank"><img src="../assets/images/icons/icon_spotify_white.svg" alt=""></a>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Videos',
}
</script>

<style scoped>
@import "../styles/variables.css";
@import '../styles/flex_class.css';
@import '../styles/texts.css';

.videos-container {
  width: 100%;
  height: fit-content;
  min-height: 100vh;

  background: url("../assets/images/photo_de_fond_video.png") no-repeat 50%;
  background-size: cover;

  position: relative;
}


.videos-container > * {
  z-index: 2;
}

/* Cadre orange */

  .cadre {
    z-index: 1;

    border: 1px solid var(--main-color);

    width: calc(100% - 135px);
    height: calc(100% - 115px);

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  @media (max-width: 600px) {

    .cadre {
      width: calc(100% - 60px);
      height: calc(100% - 50px);

      display: none;
    }

  }

/* Titre */

  h1 {
    z-index: 1;

    margin-bottom: 15px;
    margin-top: 100px;
  }

  @media (max-width: 900px) {

    h1 {
      font-size: 70px;
    }

  }

  @media (max-width: 600px) {

    h1 {
      font-size: 36px;

      margin-top: 66px;
      margin-bottom: 15px;
    }

  }

/* Vidéos */

  section {
    z-index: 1;
  }

  iframe {
    width: 400px;
    height: 230px;

    margin: 15px 80px;
  }

  @media (max-width: 600px) {

    iframe {
      width: 100%;
      height: calc(100vw * 0.57);

      margin: 0;
      margin-top: 35px;
      margin-bottom: 50px;
    }

  }

/* Liens vers les réseaux sociaux */

  .links-social-media {
    margin-top: 50px;
    margin-bottom: 175px;
  }

  .links-social-media a {
    margin: 0 23px;
  }

  .links-social-media img {
    height: 60px;
  }

  @media (max-width: 600px) {

    .links-social-media {
      margin-top: 0;
      margin-bottom: 65px;
    }

    .links-social-media a {
      margin: 0 19px;
    }

    .links-social-media img {
      height: 30px;
    }

  }

</style>