<template>
  <div id="app">
    <NavMobile></NavMobile>
    <Header></Header>
    <Disks></Disks>
    <Images></Images>
    <About></About>
    <Videos></Videos>
    <Contact></Contact>
  </div>
</template>

<script>
import NavMobile from "@/components/NavMobile";
import Header from "@/components/Header";
import Disks from "@/components/Disks";
import Images from "@/components/Images";
import About from "@/components/About";
import Videos from "@/components/Videos";
import Contact from "@/components/Contact";

export default {
  name: 'App',
  components: {
    NavMobile,
    Header,
    Disks,
    Images,
    About,
    Videos,
    Contact,
  },
  mounted() {
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener('click', function (e) {
        e.preventDefault();

        document.querySelector(this.getAttribute('href')).scrollIntoView({
          behavior: 'smooth'
        });
      });
    });
  }
}
</script>

<style>

body {
  background: white;
  margin: 0;
  overflow-x: hidden;

  height: fit-content;
  width: 100%;
}

</style>
