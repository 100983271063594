<template>
  <div class="contact-container f f-c f-nw f-aic f-jcc" id="contact">

    <div class="cadre"></div>

    <h1 class="video-contact-title-style">Contact</h1>

    <p class="tel"><span>+</span>352 691 318 581</p>
    <p>contact@manuela-rufolo.com</p>

    <a style="text-decoration: none;" href="mailto:contact@manuela-rufolo.com"><button class="f f-aic f-jcc"><span>Contact</span></button></a>

    <a href="#home"><img src="../assets/images/icons/fleche_contact.svg" alt=""></a>

  </div>
</template>

<script>
export default {
  name: 'Contact',
}
</script>

<style scoped>
@import "../styles/variables.css";
@import '../styles/flex_class.css';
@import '../styles/texts.css';

.contact-container {
  width: 100%;
  height: fit-content;
  min-height: 100vh;

  background: url("../assets/images/image_de_fond_contact.png") no-repeat 50%;
  background-size: cover;

  position: relative;
}

.contact-container * {
  z-index: 1;
}

@media (max-width: 550px) {

  .contact-container {
    justify-content: flex-start;
  }

}

/* Cadre orange */

  .cadre {
    z-index: 0;

    border: 1px solid var(--main-color);

    width: calc(100% - 135px);
    height: calc(100% - 115px);

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  @media (max-width: 550px) {

    .cadre {
      width: calc(100% - 60px);
      height: calc(100% - 50px);
    }

  }

/* Titre */

  h1 {
    margin-bottom: 160px;
  }

  @media (max-width: 900px) {

    h1 {
      font-size: 70px;
    }

  }

  @media (max-width: 550px) {

    h1 {
      font-size: 36px;

      margin-bottom: 70px;
      margin-top: 50px;
    }

  }

/* Textes */

  span {
    font-family: "Bodoni 72";
  }

  p {
    font-family: "Bodoni Moda";
    color: var(--primary-text-color);
    font-size: 30px;

    margin: 0;
  }

  @media (max-width: 550px) {

    p {
      font-size: 25px;
    }

  }

  @media (max-width: 420px) {

    p {
      font-size: 20px;
    }

  }

  @media (max-width: 320px) {

    p {
      font-size: 15px;
    }

  }

/* Bouton */

  button {
    border: 1px solid var(--primary-text-color);
    background: none;

    height: 60px;
    width: 160px;

    margin-top: 26px;
    margin-bottom: 50px;

    transition: border 0.2s;
  }

  button span {
    font-family: "Bodoni 72";
    font-size: 20px;
    text-transform: uppercase;
    color: var(--primary-text-color);

    transition: color 0.2s;
  }

  /*@media (max-width: 550px) {

    button {
      margin-bottom: 50vh;
    }

  }*/

  /* Bouton onHover */

    button:hover {
      border: 1px solid var(--main-color);

      cursor: pointer;
    }

    button:hover span {
      color: var(--main-color);
    }

/* Bouton retour vers le haut */

  a img {
    height: 72px;
    width: auto;

    position: absolute;
    right: 110px;
    bottom: 100px;
  }

  @media (max-width: 550px) {

    a img {
      height: 52px;

      position: absolute;
      right: 50px;
      bottom: 40px;
    }

  }

</style>