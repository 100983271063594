<template>
  <div class="hamburger" @click="toggleMenu" :class="[{'active': isActive}]">
    <div class="line-top line"></div>
    <div class="line-bottom line"></div>
  </div>

  <nav class="nav-container-mobile hide-on-pc" v-if="isActive" :class="[{'active': isActiveLater}]">

    <img class="logo-trampoline-saut" src="../assets/images/logos/logo_manuela.svg" alt="TRAMPOLINE">

    <div class="line-to-separate"></div>

    <div class="links-contacts-container">
      <a href="#home" class="nav-mobile-link-style" @click="toggleMenu">Home</a>
      <a href="#music" class="nav-mobile-link-style" @click="toggleMenu">Music</a>
      <a href="#about" class="nav-mobile-link-style" @click="toggleMenu">About Manuela</a>
      <a href="#videos" class="nav-mobile-link-style" @click="toggleMenu">Videos</a>
      <a href="#contact" class="nav-mobile-link-style" @click="toggleMenu">Contact</a>

      <p>+352 691 318 581</p>
      <p>contact@manuela-rufolo.com</p>
    </div>

  </nav>
</template>

<script>
export default {
  name: 'Nav',
  data() {
    return {
      'isActive': false,
      'isActiveLater': false,
    }
  },
  mounted() {
    window.addEventListener("resize", this.closeMenuOnLaptop);
  },
  methods: {
    closeMenuOnLaptop() {
      // get width size
      let widthSize = window.innerWidth;
      if (widthSize > 1370) {
        // Close menu
        this.isActive = false;
        this.isActiveLater = false; // pas besoin d'animation
        this.scrollStart();
      }
    },
    /***
     * Permet de gérer l'état du menu
     * Interchange Ouvert et Fermé
     */
    toggleMenu() {
      this.isActive = !this.isActive;
      this.toggleLater();

      // Scroll toggle
      if (this.isActive) {
        this.scrollStop();
      }
      else {
        this.scrollStart();
      }
    },
    /***
     * Fonctionne comme toggleMenu, mais tardivement
     * Utilisé pour les animations
     */
    toggleLater() {
      var vm = this;
      setTimeout(function() {
        vm.isActiveLater = !vm.isActiveLater;
      },100);
    },
    /***
     * Empeche de scroller
     * Utilisé quand le menu est ouvert
     */
    scrollStop() {
      document.body.style.overflowY = "hidden";
    },
    /***
     * Permet de scroller
     * Utilisé quand le menu est fermé
     */
    scrollStart() {
      document.body.style.overflowY = "auto";
    }
  }
}
</script>

<style scoped>
@import "../styles/variables.css";
@import '../styles/flex_class.css';
@import '../styles/texts.css';

/* Mobile */
@media only screen and (max-width: 1370px) {
  .hide-on-pc {
    display: initial!important;
  }

  /* Enlever le menu pc */
  .hide-on-mobile {
    display: none!important;
  }

  /* Hamburger basique */
  .hamburger {
    /* Couleur du contenu en fonction des elements derrière */
    mix-blend-mode: difference;

    position: fixed;
    right: 14px;
    top: 18px;
    z-index: 200;

    height: 30px;
    width: 30px;

    cursor: pointer;
  }
  .hamburger .line {
    background: #FFFFFF;

    height: 3px;
    width: 100%;

    position: absolute;

    transition: all 0.2s;
  }
  .hamburger .line-top {
    top: 0;
    right: -50%;

    transform: translateX(-50%);
  }
  .hamburger .line-bottom {
    width: 50%;

    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  /* Hamburger active */
  .hamburger.active {
    /* Couleur du contenu en fonction des elements derrière */
    mix-blend-mode: initial;
  }
  .hamburger.active .line {
    top: 50%;
    right: 50%;
  }
  .hamburger.active .line-top {
    transform: translateX(50%) translateY(-50%) rotate(-45deg);
  }
  .hamburger.active .line-bottom {
    width: 100%;
    transform: translateX(50%) translateY(-50%) rotate(45deg);
  }

  /* Menu hamburger déployé */
  nav.nav-container-mobile {
    position: fixed;
    z-index: 190;
    background: var(--main-color);
    height: 100%;
    width: 100%;

    /* effet d'apparition */
    transition: all 0.2s;
    opacity: 0;
  }
  nav.nav-container-mobile.active {
    opacity: 1;
  }

  nav.nav-container-mobile img {
    position: absolute;
    top: 11px;
    left: 19px;

    width: 250px;
  }
  nav.nav-container-mobile .links-contacts-container {
    position: absolute;
    top: calc(50% + 25px);
    right: 30px;
    transform: translateY(-50%);

    font-family: 'Open Sans';
    color: #FFFFFF;
    font-size: 20px;

    text-align: right;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }
  nav.nav-container-mobile a {
    margin: 0;
    margin-bottom: 40px;
    font-size: 20px;
  }
  nav.nav-container-mobile p, nav.nav-container-mobile b {
    margin: 0;
  }
  nav.nav-container-mobile .line-to-separate {
    width: calc(100% - 95px);
    height: 1px;
    background: #FFFFFF;

    margin-top: 85px;
    margin-left: 75px;
  }
  nav.nav-container-mobile b {
    margin-top: 25px;
  }
}

.hide-on-pc {
  display: none;
}

.nav-container {
  position: fixed;
  right: 30px;
  top: 50%;
  transform: translateY(-50%) translateX(48%) rotate(-90deg);
  z-index: 100;

  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;

  height: 35px;
  width: 100vh;

  /* Couleur du contenu en fonction des elements derrière */
  mix-blend-mode: difference;
}

.vertical-line {
  width: calc(100vh - 825px);
  max-width: 120px;
  height: 0;
  border-bottom: 2px solid #FFFFFF;
  margin: 0 28px;
}

a {
  height: 100%;
  margin: 0 28px;

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
  color: #FFFFFF;

  /* Met ... à la fin si il n'y a plus de place */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  position: relative;
  text-decoration: none;

  transition: color 0.2s;
}

/* ligne en dessous des liens au survole */
.line-hover {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  border-bottom: 2px solid white;

  transition: all 0.2s;
  width: 0px;
}
a:hover {
  color: var(--secondary-text-color);
}
a:hover .line-hover {
  width: 30px;
}
.line-hover.active {
  width: 30px;
}
</style>